var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DAV-workshop__trunk"
  }, [_c('Spinner', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    attrs: {
      "propsPartial": true
    }
  }), _c('div', {
    staticClass: "DAV-panelArea",
    class: {
      'DAV-panelArea__option-mode': _vm.$route.path === '/option'
    }
  }, [_c('div', {
    staticClass: "DAV-panelArea__wrap"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.updateVisible,
      expression: "!updateVisible"
    }],
    staticClass: "DAV-panelArea__form"
  }, [_c('a', {
    staticClass: "DAV-panelArea__form--link",
    class: {
      cursor: _vm.$route.path !== '/option'
    },
    on: {
      "click": _vm.componentNameEdit
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.componentName))])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.updateVisible,
      expression: "updateVisible"
    }],
    staticClass: "DAV-panelArea__form DAV-panelArea__form--update"
  }, [_c('div', {
    staticClass: "formLayout"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.componentName,
      expression: "componentName"
    }],
    staticClass: "formLayout__input",
    attrs: {
      "type": "text",
      "maxlength": "15"
    },
    domProps: {
      "value": _vm.componentName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.componentName = $event.target.value;
      }
    }
  }), _c('a', {
    staticClass: "formLayout__input--remove",
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.componentNameRemove
    }
  }, [_vm._v(" 지우기 ")])]), _c('a', {
    staticClass: "DAV-panelArea__form--save",
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.componentNameUpdate
    }
  }, [_vm._v(" 저장 ")])])])]), _c('div', {
    staticClass: "DAV-tabArea",
    class: {
      'DAV-tabArea--option-mode': _vm.$route.path === '/option'
    }
  }, [_c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.path !== '/option',
      expression: "$route.path !== '/option'"
    }],
    staticClass: "DAV-tabArea__nav"
  }, [_c('li', {
    staticClass: "DAV-tabArea__link",
    class: {
      'DAV-tabArea__link--selected': !_vm.isTabVisible
    },
    on: {
      "click": _vm.tabVisible
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("디자인")])]), _vm.$route.path !== '/option' ? _c('li', {
    staticClass: "DAV-tabArea__link",
    class: {
      'DAV-tabArea__link--selected': _vm.isTabVisible
    },
    on: {
      "click": _vm.tabVisible
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("프레임")])]) : _vm._e()]), _c('h3', {
    staticClass: "blind-a11y"
  }, [_vm._v("디자인")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isTabVisible,
      expression: "!isTabVisible"
    }],
    staticClass: "DAV-tabArea__design",
    class: {
      'DAV-tabArea__design--editable': _vm.editable && _vm.$route.path === '/option',
      'DAV-tabArea__design--uneditable': !_vm.editable && _vm.$route.path === '/option'
    }
  }, [!Object.keys(_vm.displayOption).length ? _c('div', {
    staticClass: "errorLayout errorLayout__content",
    class: {
      'errorLayout__option-mode': _vm.$route.path === '/option'
    }
  }, [_c('p', [_vm._v("옵션이 없습니다.")])]) : _c('div', _vm._l(_vm.displayOption, function (option, key) {
    var _option$componentOpti, _option$componentOpti2;

    return _c('div', {
      key: key
    }, [_c('section', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.$route.path === '/option' ? true : _vm.getDeviceType === 'desktop' && option.componentOption.displayOnPc || _vm.getDeviceType === 'mobile' && option.componentOption.displayOnMobile,
        expression: "\n              $route.path === '/option'\n                ? true\n                : (getDeviceType === 'desktop' &&\n                    option.componentOption.displayOnPc) ||\n                  (getDeviceType === 'mobile' &&\n                    option.componentOption.displayOnMobile)\n            "
      }]
    }, [_c('div', {
      staticClass: "DAV-titleBar"
    }, [_c('h4', [_vm._v(_vm._s(option.componentOption.name))]), _c('button', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: option.componentOption.help && option.componentOption.displayHelp,
        expression: "\n                  option.componentOption.help &&\n                  option.componentOption.displayHelp\n                "
      }],
      staticClass: "DAV-titleBar__help",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.toggleHelpLayer(key, true);
        }
      }
    }, [_vm._v(" 도움말 ")]), option.componentOption.componentTypeId !== 19 ? _c('span', {
      staticClass: "DAV-titleBar__device",
      class: _vm.setBlockClass(option.componentOption.displayOnMobile, option.componentOption.displayOnPc)
    }, [_vm._v(" 디바이스 타입 ")]) : _vm._e(), option.componentOption.hideable && option.componentOption.componentTypeId !== 19 ? _c('button', {
      staticClass: "DAV-titleBar__toggle",
      class: {
        'DAV-titleBar__toggle--active': option.visible
      },
      on: {
        "click": function click($event) {
          return _vm.updateUse(option.componentOptionId, option.visible);
        }
      }
    }, [_c('span', [_vm._v("사용 여부")])]) : _vm._e(), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.helpLayer[key],
        expression: "helpLayer[key]"
      }, {
        name: "click-outside",
        rawName: "v-click-outside:toggleHelpLayer",
        value: key,
        expression: "key",
        arg: "toggleHelpLayer"
      }],
      staticClass: "titleGuide"
    }, [_c('button', {
      staticClass: "titleGuide--close",
      on: {
        "click": function click($event) {
          return _vm.toggleHelpLayer(key, false);
        }
      }
    }, [_vm._v(" 닫기 ")]), _c('div', {
      staticClass: "titleGuide__text",
      domProps: {
        "innerHTML": _vm._s((_option$componentOpti = option.componentOption) === null || _option$componentOpti === void 0 ? void 0 : (_option$componentOpti2 = _option$componentOpti.help) === null || _option$componentOpti2 === void 0 ? void 0 : _option$componentOpti2.replace(/(?:\r\n|\r|\n)/g, '<br />'))
      },
      on: {
        "click": _vm.tooltipCheck
      }
    })])]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: option.visible,
        expression: "option.visible"
      }],
      staticClass: "DAV-tabArea__block"
    }, [option.componentOption.componentTypeId === 1 ? _c('Toggle', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption,
        "updateProperty": _vm.updateProperty
      }
    }) : option.componentOption.componentTypeId === 2 ? _c('TextDisplay', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption,
        "updateProperty": _vm.updateProperty
      }
    }) : option.componentOption.componentTypeId === 3 ? _c('Textarea', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption,
        "updateProperty": _vm.updateProperty
      }
    }) : option.componentOption.componentTypeId === 4 ? _c('Radio', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption,
        "updateProperty": _vm.updateProperty
      }
    }) : option.componentOption.componentTypeId === 5 ? _c('Checkbox', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption,
        "updateProperty": _vm.updateProperty
      }
    }) : option.componentOption.componentTypeId === 6 ? _c('ImageDisplay', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption,
        "updateProperty": _vm.updateProperty
      }
    }) : option.componentOption.componentTypeId === 7 ? _c('ImageURLDisplay', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption,
        "updateProperty": _vm.updateProperty
      }
    }) : option.componentOption.componentTypeId === 8 ? _c('Select', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption,
        "updateProperty": _vm.updateProperty
      }
    }) : option.componentOption.componentTypeId === 9 ? _c('Number', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption,
        "updateProperty": _vm.updateProperty
      }
    }) : option.componentOption.componentTypeId === 10 ? _c('ColorPicker', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption,
        "updateProperty": _vm.updateProperty
      }
    }) : option.componentOption.componentTypeId === 11 ? _c('IconPicker', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption
      }
    }) : option.componentOption.componentTypeId === 12 ? _c('TextURLDisplay', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption,
        "updateProperty": _vm.updateProperty
      }
    }) : option.componentOption.componentTypeId === 13 ? _c('TextImageURLDisplay', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption,
        "updateProperty": _vm.updateProperty
      }
    }) : option.componentOption.componentTypeId === 14 ? _c('MultiTextDisplay', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption,
        "updateProperty": _vm.updateProperty
      }
    }) : option.componentOption.componentTypeId === 15 ? _c('MultiTextURLDisplay', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption,
        "updateProperty": _vm.updateProperty
      }
    }) : option.componentOption.componentTypeId === 16 ? _c('MultiImageDisplay', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption,
        "updateProperty": _vm.updateProperty
      }
    }) : option.componentOption.componentTypeId === 17 ? _c('MultiImageURLDisplay', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption,
        "updateProperty": _vm.updateProperty
      }
    }) : option.componentOption.componentTypeId === 18 ? _c('MultiTextImageURLDisplay', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption,
        "updateProperty": _vm.updateProperty
      }
    }) : option.componentOption.componentTypeId === 19 ? _c('ProductPicker', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption,
        "updateProperty": _vm.updateProperty,
        "isLoading": function isLoading(v) {
          return _vm.isLoading = v;
        }
      }
    }) : option.componentOption.componentTypeId === 20 ? _c('Html', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption
      }
    }) : option.componentOption.componentTypeId === 21 ? _c('FontPicker', {
      attrs: {
        "option": option
      },
      on: {
        "updateOption": _vm.updateOption,
        "updateProperty": _vm.updateProperty
      }
    }) : _c('p', [_vm._v("정의되지 않은 옵션 타입입니다.")])], 1)])]);
  }), 0)]), _c('h3', {
    staticClass: "blind-a11y"
  }, [_vm._v("프레임")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isTabVisible,
      expression: "isTabVisible"
    }],
    staticClass: "DAV-tabArea__frame"
  }, [_c('section', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.marginDisplay,
      expression: "marginDisplay"
    }]
  }, [_c('div', {
    staticClass: "DAV-titleBar"
  }, [_c('h4', [_vm._v(" 여백 "), _c('tooltip', {
    attrs: {
      "text": "\uCEF4\uD3EC\uB10C\uD2B8 \uC0C1, \uD558, \uC88C, \uC6B0 \uC5EC\uBC31\uC744 \uC124\uC815\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.<br/>\n              \uFF65 \uC5EC\uBC31 \uAC12\uC740 0\uC744 \uD3EC\uD568\uD55C \uC591\uC758 \uC815\uC218\uB9CC \uC124\uC815 \uAC00\uB2A5\uD558\uACE0 \uADF8 \uC678 \uBB38\uC790 \uC785\uB825\uC740 \uC81C\uD55C\uB429\uB2C8\uB2E4.<br/>\n              \uFF65 \uC5EC\uBC31 \uC124\uC815\uC758 \uC911\uC559 \uBC15\uC2A4 \uC785\uB825 \uC2DC \uC0C1, \uD558, \uC88C, \uC6B0 \uBAA8\uB450 \uB3D9\uC77C\uD55C \uAC12\uC774 \uC790\uB3D9 \uC801\uC6A9\uB429\uB2C8\uB2E4.<br/>\n              <a class='bold' href='".concat(_vm.helpUrl, "/board-detail?id=10&boardId=1&dtPage=1' target='_blank'>\uC5EC\uBC31 \uC124\uC815 \uC774\uD574\uD558\uAE30 ></a>")
    }
  })], 1)]), _c('div', {
    staticClass: "DAV-tabArea__block"
  }, [_c('div', {
    staticClass: "frmBlock__boxWrap"
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDisplayDevice === 'union',
      expression: "isDisplayDevice === 'union'"
    }],
    staticClass: "currentDevice currentDevice__union"
  }, [_vm._v(" 데스크탑 + 모바일 ")]), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDisplayDevice === 'mobile',
      expression: "isDisplayDevice === 'mobile'"
    }],
    staticClass: "currentDevice currentDevice__mobile"
  }, [_vm._v(" 모바일 ")]), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDisplayDevice === 'pc',
      expression: "isDisplayDevice === 'pc'"
    }],
    staticClass: "currentDevice currentDevice__pc"
  }, [_vm._v(" 데스크탑 ")]), _c('div', {
    staticClass: "spaceBox"
  }, [_c('div', {
    staticClass: "spaceBox__field"
  }, [_c('span', [_vm._v("px")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.marginTop,
      expression: "marginTop"
    }],
    staticClass: "frmBlock__textField",
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.marginTop
    },
    on: {
      "keyup": _vm.changeSpace,
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.marginTop = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "spaceBox__center"
  }, [_c('div', {
    staticClass: "spaceBox__field"
  }, [_c('span', [_vm._v("px")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.marginLeft,
      expression: "marginLeft"
    }],
    staticClass: "frmBlock__textField",
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.marginLeft
    },
    on: {
      "keyup": _vm.changeSpace,
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.marginLeft = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "spaceBox__center--inner"
  }, [_c('div', {
    staticClass: "spaceBox__field"
  }, [_c('span', [_vm._v("px")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.marginUnit,
      expression: "marginUnit"
    }],
    staticClass: "frmBlock__textField",
    attrs: {
      "type": "number",
      "min": "0",
      "placeholder": "--"
    },
    domProps: {
      "value": _vm.marginUnit
    },
    on: {
      "keyup": function keyup($event) {
        return _vm.changeSpace($event, 'unit');
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.marginUnit = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "spaceBox__field"
  }, [_c('span', [_vm._v("px")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.marginRight,
      expression: "marginRight"
    }],
    staticClass: "frmBlock__textField",
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.marginRight
    },
    on: {
      "keyup": _vm.changeSpace,
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.marginRight = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "spaceBox__field"
  }, [_c('span', [_vm._v("px")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.marginBottom,
      expression: "marginBottom"
    }],
    staticClass: "frmBlock__textField",
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.marginBottom
    },
    on: {
      "keyup": _vm.changeSpace,
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.marginBottom = $event.target.value;
      }
    }
  })])])])])]), _c('section', [_c('div', {
    staticClass: "DAV-titleBar"
  }, [_c('h4', [_vm._v(" 디바이스 노출 설정 "), _c('tooltip', {
    attrs: {
      "text": "\uB370\uC2A4\uD06C\uD0D1\uACFC \uBAA8\uBC14\uC77C \uD574\uC0C1\uB3C4\uC5D0 \uB530\uB77C \uCEF4\uD3EC\uB10C\uD2B8 \uB178\uCD9C \uC5EC\uBD80\uB97C \uC124\uC815\uD558\uC5EC \uD574\uC0C1\uB3C4 \uBCC4 \uCEF4\uD3EC\uB10C\uD2B8 \uAD6C\uC131\uC774 \uAC00\uB2A5\uD569\uB2C8\uB2E4.<br/>\n              \uFF65 \uC804\uCCB4 : \uBAA8\uB4E0 \uD574\uC0C1\uB3C4\uC5D0 \uCEF4\uD3EC\uB10C\uD2B8\uB97C \uB178\uCD9C \uD569\uB2C8\uB2E4.<br/>\n              \uFF65 \uBAA8\uBC14\uC77C : \uBAA8\uBC14\uC77C \uAE30\uC900\uC778 1023px \uC774\uD558 \uD574\uC0C1\uB3C4 \uC5D0\uC11C\uB9CC \uCEF4\uD3EC\uB10C\uD2B8\uB97C \uB178\uCD9C \uD569\uB2C8\uB2E4.<br/>\n              \uFF65 \uB370\uC2A4\uD06C\uD0D1 : \uB370\uC2A4\uD06C\uD0D1 \uAE30\uC900\uC778 1024px \uC774\uC0C1 \uD574\uC0C1\uB3C4 \uC5D0\uC11C\uB9CC \uCEF4\uD3EC\uB10C\uD2B8\uB97C \uB178\uCD9C \uD569\uB2C8\uB2E4."
    }
  })], 1)]), _c('div', {
    staticClass: "frmBlock__display"
  }, [_c('button', {
    staticClass: "deviceDisplay__union",
    class: {
      'deviceDisplay--active': _vm.isDisplayDevice === 'union'
    },
    on: {
      "click": function click($event) {
        return _vm.setDisplayDevice('union');
      }
    }
  }, [_c('span', [_vm._v("전체")])]), _c('button', {
    staticClass: "deviceDisplay__mobile",
    class: {
      'deviceDisplay--active': _vm.isDisplayDevice === 'mobile'
    },
    on: {
      "click": function click($event) {
        return _vm.setDisplayDevice('mobile');
      }
    }
  }, [_c('span', [_vm._v("모바일")])]), _c('button', {
    staticClass: "deviceDisplay__pc",
    class: {
      'deviceDisplay--active': _vm.isDisplayDevice === 'pc'
    },
    on: {
      "click": function click($event) {
        return _vm.setDisplayDevice('pc');
      }
    }
  }, [_c('span', [_vm._v("데스크탑")])])])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }