<template>
  <div class="DAV-workshop__trunk">
    <Spinner v-show="isLoading" :propsPartial="true" />
    <div
      class="DAV-panelArea"
      :class="{'DAV-panelArea__option-mode': $route.path === '/option'}"
    >
      <div class="DAV-panelArea__wrap">
        <div class="DAV-panelArea__form" v-show="!updateVisible">
          <a
            class="DAV-panelArea__form--link"
            :class="{cursor: $route.path !== '/option'}"
            @click="componentNameEdit"
          >
            <span>{{ componentName }}</span>
          </a>
        </div>
        <div
          class="DAV-panelArea__form DAV-panelArea__form--update"
          v-show="updateVisible"
        >
          <div class="formLayout">
            <input
              type="text"
              class="formLayout__input"
              v-model="componentName"
              maxlength="15"
            />
            <a
              href="#"
              class="formLayout__input--remove"
              @click="componentNameRemove"
            >
              지우기
            </a>
          </div>
          <a
            href="#"
            class="DAV-panelArea__form--save"
            @click="componentNameUpdate"
          >
            저장
          </a>
        </div>
      </div>
    </div>
    <div
      class="DAV-tabArea"
      :class="{'DAV-tabArea--option-mode': $route.path === '/option'}"
    >
      <ul class="DAV-tabArea__nav" v-show="$route.path !== '/option'">
        <li
          class="DAV-tabArea__link"
          :class="{'DAV-tabArea__link--selected': !isTabVisible}"
          @click="tabVisible"
        >
          <a href="#">디자인</a>
        </li>
        <li
          v-if="$route.path !== '/option'"
          class="DAV-tabArea__link"
          :class="{'DAV-tabArea__link--selected': isTabVisible}"
          @click="tabVisible"
        >
          <a href="#">프레임</a>
        </li>
      </ul>
      <h3 class="blind-a11y">디자인</h3>
      <div
        class="DAV-tabArea__design"
        :class="{
          'DAV-tabArea__design--editable':
            editable && $route.path === '/option',
          'DAV-tabArea__design--uneditable':
            !editable && $route.path === '/option'
        }"
        v-show="!isTabVisible"
      >
        <div
          class="errorLayout errorLayout__content"
          :class="{'errorLayout__option-mode': $route.path === '/option'}"
          v-if="!Object.keys(displayOption).length"
        >
          <p>옵션이 없습니다.</p>
        </div>
        <div v-else>
          <div v-for="(option, key) in displayOption" :key="key">
            <section
              v-show="
                $route.path === '/option'
                  ? true
                  : (getDeviceType === 'desktop' &&
                      option.componentOption.displayOnPc) ||
                    (getDeviceType === 'mobile' &&
                      option.componentOption.displayOnMobile)
              "
            >
              <div class="DAV-titleBar">
                <h4>{{ option.componentOption.name }}</h4>
                <button
                  class="DAV-titleBar__help"
                  @click.stop="toggleHelpLayer(key, true)"
                  v-show="
                    option.componentOption.help &&
                    option.componentOption.displayHelp
                  "
                >
                  도움말
                </button>
                <span
                  v-if="option.componentOption.componentTypeId !== 19"
                  class="DAV-titleBar__device"
                  :class="
                    setBlockClass(
                      option.componentOption.displayOnMobile,
                      option.componentOption.displayOnPc
                    )
                  "
                >
                  디바이스 타입
                </span>
                <button
                  class="DAV-titleBar__toggle"
                  :class="{
                    'DAV-titleBar__toggle--active': option.visible
                  }"
                  v-if="
                    option.componentOption.hideable &&
                    option.componentOption.componentTypeId !== 19
                  "
                  @click="updateUse(option.componentOptionId, option.visible)"
                >
                  <span>사용 여부</span>
                </button>

                <div
                  class="titleGuide"
                  v-show="helpLayer[key]"
                  v-click-outside:toggleHelpLayer="key"
                >
                  <button
                    class="titleGuide--close"
                    @click="toggleHelpLayer(key, false)"
                  >
                    닫기
                  </button>
                  <div
                    class="titleGuide__text"
                    @click="tooltipCheck"
                    v-html="
                      option.componentOption?.help?.replace(
                        /(?:\r\n|\r|\n)/g,
                        '<br />'
                      )
                    "
                  />
                </div>
              </div>
              <div class="DAV-tabArea__block" v-show="option.visible">
                <!-- 1: Toggle -->
                <Toggle
                  v-if="option.componentOption.componentTypeId === 1"
                  :option="option"
                  @updateOption="updateOption"
                  @updateProperty="updateProperty"
                />
                <!-- 2: Text Field -->
                <TextDisplay
                  v-else-if="option.componentOption.componentTypeId === 2"
                  :option="option"
                  @updateOption="updateOption"
                  @updateProperty="updateProperty"
                />
                <!-- 3: Textarea -->
                <Textarea
                  v-else-if="option.componentOption.componentTypeId === 3"
                  :option="option"
                  @updateOption="updateOption"
                  @updateProperty="updateProperty"
                />
                <!-- 4: Radio -->
                <Radio
                  v-else-if="option.componentOption.componentTypeId === 4"
                  :option="option"
                  @updateOption="updateOption"
                  @updateProperty="updateProperty"
                />
                <!-- 5: Checkbox -->
                <Checkbox
                  v-else-if="option.componentOption.componentTypeId === 5"
                  :option="option"
                  @updateOption="updateOption"
                  @updateProperty="updateProperty"
                />
                <!-- 6: Image Display -->
                <ImageDisplay
                  v-else-if="option.componentOption.componentTypeId === 6"
                  :option="option"
                  @updateOption="updateOption"
                  @updateProperty="updateProperty"
                />
                <!-- 7: Image + URL Display -->
                <ImageURLDisplay
                  v-else-if="option.componentOption.componentTypeId === 7"
                  :option="option"
                  @updateOption="updateOption"
                  @updateProperty="updateProperty"
                />
                <!-- 8: Select -->
                <Select
                  v-else-if="option.componentOption.componentTypeId === 8"
                  :option="option"
                  @updateOption="updateOption"
                  @updateProperty="updateProperty"
                />
                <!-- 9: Number -->
                <Number
                  v-else-if="option.componentOption.componentTypeId === 9"
                  :option="option"
                  @updateOption="updateOption"
                  @updateProperty="updateProperty"
                />
                <!-- 10: Color Picker -->
                <ColorPicker
                  v-else-if="option.componentOption.componentTypeId === 10"
                  :option="option"
                  @updateOption="updateOption"
                  @updateProperty="updateProperty"
                />
                <!-- 11: Icon Picker -->
                <IconPicker
                  v-else-if="option.componentOption.componentTypeId === 11"
                  :option="option"
                  @updateOption="updateOption"
                />
                <!-- 12: Text + URL Display -->
                <TextURLDisplay
                  v-else-if="option.componentOption.componentTypeId === 12"
                  :option="option"
                  @updateOption="updateOption"
                  @updateProperty="updateProperty"
                />
                <!-- 13: Text + Image + URL Display (Logo) -->
                <TextImageURLDisplay
                  v-else-if="option.componentOption.componentTypeId === 13"
                  :option="option"
                  @updateOption="updateOption"
                  @updateProperty="updateProperty"
                />
                <!-- 14: Multi Text -->
                <MultiTextDisplay
                  v-else-if="option.componentOption.componentTypeId === 14"
                  :option="option"
                  @updateOption="updateOption"
                  @updateProperty="updateProperty"
                />
                <!-- 15: Multi Text + URL -->
                <MultiTextURLDisplay
                  v-else-if="option.componentOption.componentTypeId === 15"
                  :option="option"
                  @updateOption="updateOption"
                  @updateProperty="updateProperty"
                />
                <!-- 16: Multi Image -->
                <MultiImageDisplay
                  v-else-if="option.componentOption.componentTypeId === 16"
                  :option="option"
                  @updateOption="updateOption"
                  @updateProperty="updateProperty"
                />
                <!-- 17: Multi Image + URL -->
                <MultiImageURLDisplay
                  v-else-if="option.componentOption.componentTypeId === 17"
                  :option="option"
                  @updateOption="updateOption"
                  @updateProperty="updateProperty"
                />
                <!-- 18: Multi Text + Image + URL -->
                <MultiTextImageURLDisplay
                  v-else-if="option.componentOption.componentTypeId === 18"
                  :option="option"
                  @updateOption="updateOption"
                  @updateProperty="updateProperty"
                />
                <!-- 19: Product Picker -->
                <ProductPicker
                  v-else-if="option.componentOption.componentTypeId === 19"
                  :option="option"
                  @updateOption="updateOption"
                  @updateProperty="updateProperty"
                  @isLoading="v => (isLoading = v)"
                />
                <!-- 20: Html -->
                <Html
                  v-else-if="option.componentOption.componentTypeId === 20"
                  :option="option"
                  @updateOption="updateOption"
                />
                <!-- 21: FontPicker -->
                <FontPicker
                  v-else-if="option.componentOption.componentTypeId === 21"
                  :option="option"
                  @updateOption="updateOption"
                  @updateProperty="updateProperty"
                />
                <!-- else -->
                <p v-else>정의되지 않은 옵션 타입입니다.</p>
              </div>
            </section>
          </div>
        </div>
      </div>
      <h3 class="blind-a11y">프레임</h3>
      <div class="DAV-tabArea__frame" v-show="isTabVisible">
        <section v-show="marginDisplay">
          <div class="DAV-titleBar">
            <h4>
              여백
              <tooltip
                :text="`컴포넌트 상, 하, 좌, 우 여백을 설정할 수 있습니다.<br/>
                ･ 여백 값은 0을 포함한 양의 정수만 설정 가능하고 그 외 문자 입력은 제한됩니다.<br/>
                ･ 여백 설정의 중앙 박스 입력 시 상, 하, 좌, 우 모두 동일한 값이 자동 적용됩니다.<br/>
                <a class='bold' href='${helpUrl}/board-detail?id=10&boardId=1&dtPage=1' target='_blank'>여백 설정 이해하기 ></a>`"
              />
            </h4>
          </div>
          <div class="DAV-tabArea__block">
            <div class="frmBlock__boxWrap">
              <span
                class="currentDevice currentDevice__union"
                v-show="isDisplayDevice === 'union'"
              >
                데스크탑 + 모바일
              </span>
              <span
                class="currentDevice currentDevice__mobile"
                v-show="isDisplayDevice === 'mobile'"
              >
                모바일
              </span>
              <span
                class="currentDevice currentDevice__pc"
                v-show="isDisplayDevice === 'pc'"
              >
                데스크탑
              </span>
              <div class="spaceBox">
                <div class="spaceBox__field">
                  <span>px</span>
                  <input
                    type="number"
                    min="0"
                    class="frmBlock__textField"
                    v-model="marginTop"
                    @keyup="changeSpace"
                  />
                </div>
                <div class="spaceBox__center">
                  <div class="spaceBox__field">
                    <span>px</span>
                    <input
                      type="number"
                      min="0"
                      class="frmBlock__textField"
                      v-model="marginLeft"
                      @keyup="changeSpace"
                    />
                  </div>
                  <div class="spaceBox__center--inner">
                    <div class="spaceBox__field">
                      <span>px</span>
                      <input
                        type="number"
                        min="0"
                        class="frmBlock__textField"
                        v-model="marginUnit"
                        placeholder="--"
                        @keyup="changeSpace($event, 'unit')"
                      />
                    </div>
                  </div>
                  <div class="spaceBox__field">
                    <span>px</span>
                    <input
                      type="number"
                      min="0"
                      class="frmBlock__textField"
                      v-model="marginRight"
                      @keyup="changeSpace"
                    />
                  </div>
                </div>
                <div class="spaceBox__field">
                  <span>px</span>
                  <input
                    type="number"
                    min="0"
                    class="frmBlock__textField"
                    v-model="marginBottom"
                    @keyup="changeSpace"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="DAV-titleBar">
            <h4>
              디바이스 노출 설정
              <tooltip
                :text="`데스크탑과 모바일 해상도에 따라 컴포넌트 노출 여부를 설정하여 해상도 별 컴포넌트 구성이 가능합니다.<br/>
                ･ 전체 : 모든 해상도에 컴포넌트를 노출 합니다.<br/>
                ･ 모바일 : 모바일 기준인 1023px 이하 해상도 에서만 컴포넌트를 노출 합니다.<br/>
                ･ 데스크탑 : 데스크탑 기준인 1024px 이상 해상도 에서만 컴포넌트를 노출 합니다.`"
              />
            </h4>
          </div>
          <div class="frmBlock__display">
            <button
              class="deviceDisplay__union"
              :class="{'deviceDisplay--active': isDisplayDevice === 'union'}"
              @click="setDisplayDevice('union')"
            >
              <span>전체</span>
            </button>
            <button
              class="deviceDisplay__mobile"
              :class="{'deviceDisplay--active': isDisplayDevice === 'mobile'}"
              @click="setDisplayDevice('mobile')"
            >
              <span>모바일</span>
            </button>
            <button
              class="deviceDisplay__pc"
              :class="{'deviceDisplay--active': isDisplayDevice === 'pc'}"
              @click="setDisplayDevice('pc')"
            >
              <span>데스크탑</span>
            </button>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleEditor = createNamespacedHelpers("ModuleEditor");
const ModuleEditorContainer = createNamespacedHelpers("ModuleEditorContainer");
const ModuleAlert = createNamespacedHelpers("ModuleAlert");
const AppHelp = process.env.VUE_APP_EXTERNAL_qpick_help_URL;

export default {
  data() {
    return {
      debounce: null,
      isDisplayDevice: null,
      updateVisible: false,
      isTabVisible: false,
      linkedComponentId: null,
      componentName: null,
      componentLinkedOptions: {},
      displayOnMobile: null,
      displayOnPc: null,
      marginDisplay: false,
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
      marginUnit: null,
      helpLayer: {},
      isLoading: false,
      helpUrl: AppHelp
    };
  },
  props: {
    partnerOptions: {
      type: Array
    },
    partnerComponentName: {
      type: String
    },
    editable: {
      type: Boolean
    }
  },
  computed: {
    displayOption() {
      return this.$route.path !== "/option"
        ? this.componentLinkedOptions
        : this.partnerOptions;
    },
    ...ModuleEditor.mapGetters(["getLinkedComponentId", "getDeviceType"]),
    ...ModuleEditorContainer.mapGetters(["getComponentContainer"])
  },
  created() {
    this.$EventBus.$on("optionLoad", event => {
      if (event === "load") {
        this.componentInit();
      }
    });

    if (this.$route.path === "/option") {
      this.componentName = this.partnerComponentName;
    }
  },
  methods: {
    // 툴팁에서 [서체 설정하기] 이벤트 감지
    tooltipCheck(e) {
      if (e.target.id === "fontPopup") {
        this.setFontEvent(true);
      }
    },
    // 옵션 사용 여부
    updateUse(componentOptionId, value) {
      if (this.$route.path === "/option") {
        this.setAlert({
          isVisible: true,
          message: "옵션 기본값은 '사용중'으로만 설정할 수 있습니다."
        });
        return false;
      }
      value = !value;

      for (let o of this.displayOption) {
        if (componentOptionId === o.componentOptionId) {
          o.visible = value;
        }
      }

      this.setEvent("render");
      this.setAttach(true);
    },
    // class binding - 컴포넌트 옵션(디바이스 타입)
    setBlockClass(displayOnMobile, displayOnPc) {
      return {
        "DAV-titleBar__device--pc": !displayOnMobile && displayOnPc,
        "DAV-titleBar__device--mobile": displayOnMobile && !displayOnPc
      };
    },
    // 디바이스 노출 세팅
    setDisplay() {
      if (this.displayOnMobile && this.displayOnPc) {
        this.isDisplayDevice = "union";
      }
      if (!this.displayOnMobile && this.displayOnPc) {
        this.isDisplayDevice = "pc";
      }
      if (this.displayOnMobile && !this.displayOnPc) {
        this.isDisplayDevice = "mobile";
      }
    },
    // 디바이스 노출 설정
    setDisplayDevice(device) {
      if (device === "union") {
        [this.displayOnMobile, this.displayOnPc] = [1, 1];
      }
      if (device === "pc") {
        [this.displayOnMobile, this.displayOnPc] = [0, 1];
      }
      if (device === "mobile") {
        [this.displayOnMobile, this.displayOnPc] = [1, 0];
      }
      this.setDisplay();

      this.getComponentContainer.get(this.linkedComponentId).displayOnMobile =
        this.displayOnMobile;
      this.getComponentContainer.get(this.linkedComponentId).displayOnPc =
        this.displayOnPc;

      this.setEvent("display");
      this.setAttach(true);
    },
    // 여백 조정
    changeSpace(e, obj) {
      let num = e.target.value;

      if (obj) {
        this.marginTop = num;
        this.marginLeft = num;
        this.marginRight = num;
        this.marginBottom = num;
      } else {
        if (
          this.marginTop === num &&
          this.marginLeft === num &&
          this.marginRight === num &&
          this.marginBottom === num
        ) {
          this.marginUnit = num;
        } else {
          this.marginUnit = null;
        }
      }

      this.getComponentContainer.get(this.linkedComponentId).etc.marginTop =
        this.marginTop;
      this.getComponentContainer.get(this.linkedComponentId).etc.marginLeft =
        this.marginLeft;
      this.getComponentContainer.get(this.linkedComponentId).etc.marginRight =
        this.marginRight;
      this.getComponentContainer.get(this.linkedComponentId).etc.marginBottom =
        this.marginBottom;
      this.setEvent("style");
      this.setAttach(true);
    },
    // 프레임 탭
    tabVisible(e) {
      if (e) e.preventDefault();
      this.isTabVisible = !this.isTabVisible;
    },
    // 도움말 레이어 show/hide
    toggleHelpLayer(key, value) {
      this.$set(this.helpLayer, key, value);
    },
    // 옵션 프로퍼티 업데이트
    updateProperty(key, modified) {
      if (this.$route.path !== "/option") return false;
      for (let o of this.displayOption) {
        if (key === o.componentOption.key) {
          this.$set(o, "modified", modified);
        }
      }
    },
    // 컴포넌트 옵션 랜더링
    updateOption(key, type, value) {
      for (let o of this.displayOption) {
        if (key === o.componentOption.key) {
          this.$set(o.value, type, value);
        }
      }
      if (this.debounce) {
        clearTimeout(this.debounce);
      }
      this.debounce = setTimeout(() => {
        this.setEvent("render");
        this.setAttach(true);
      }, 300);
    },
    // 컴포넌트명 편집
    componentNameEdit(e) {
      if (e) e.preventDefault();
      if (this.$route.path === "/option") return false;
      this.updateVisible = true;
    },
    // 컴포넌트명 삭제
    componentNameRemove(e) {
      if (e) e.preventDefault();
      this.componentName = "";
    },
    // 컴포넌트명 저장
    componentNameUpdate(e) {
      if (e) e.preventDefault();
      if (!this.componentName.trim()) return;

      this.getComponentContainer.get(this.linkedComponentId).name =
        this.componentName;

      this.updateVisible = false;

      this.setAttach(true);
    },
    // 컴포넌트 옵션 초기화
    componentInit() {
      this.linkedComponentId = this.getLinkedComponentId;
      if (!this.getComponentContainer.get(this.linkedComponentId)) return;

      this.componentName = this.getComponentContainer.get(
        this.linkedComponentId
      ).name;
      this.componentLinkedOptions = this.getComponentContainer.get(
        this.linkedComponentId
      ).linkedOptions;

      console.log(this.componentLinkedOptions);

      // console.log(
      //   this.getComponentContainer.get(this.linkedComponentId).linkedOptions
      // );

      // 디바이스 노출 설정
      this.displayOnMobile = this.getComponentContainer.get(
        this.linkedComponentId
      ).displayOnMobile;
      this.displayOnPc = this.getComponentContainer.get(
        this.linkedComponentId
      ).displayOnPc;
      this.setDisplay();

      // 여백 설정
      this.marginDisplay =
        this.getComponentContainer.get(this.linkedComponentId).component
          .useBlank === 0
          ? false
          : true;
      this.marginTop = this.getComponentContainer.get(
        this.linkedComponentId
      ).etc.marginTop;
      this.marginLeft = this.getComponentContainer.get(
        this.linkedComponentId
      ).etc.marginLeft;
      this.marginRight = this.getComponentContainer.get(
        this.linkedComponentId
      ).etc.marginRight;
      this.marginBottom = this.getComponentContainer.get(
        this.linkedComponentId
      ).etc.marginBottom;
      if (
        this.marginTop === this.marginLeft &&
        this.marginLeft === this.marginRight &&
        this.marginRight === this.marginBottom
      ) {
        this.marginUnit = this.marginTop;
      }
    },

    ...ModuleEditor.mapMutations(["setAttach", "setFontEvent"]),
    ...ModuleEditorContainer.mapMutations([
      "setEvent",
      "setComponentLayout",
      "setComponentContainer"
    ]),
    ...ModuleAlert.mapMutations(["setAlert"])
  },
  mounted() {
    if (this.$route.path !== "/option") this.componentInit();
  },
  components: {
    Toggle: () => import("@/components/options/Toggle"),
    TextDisplay: () => import("@/components/options/TextDisplay"),
    Textarea: () => import("@/components/options/Textarea"),
    Radio: () => import("@/components/options/Radio"),
    Checkbox: () => import("@/components/options/Checkbox"),
    ImageDisplay: () => import("@/components/options/ImageDisplay"),
    ImageURLDisplay: () => import("@/components/options/ImageURLDisplay"),
    Select: () => import("@/components/options/Select"),
    Number: () => import("@/components/options/Number"),
    ColorPicker: () => import("@/components/options/ColorPicker"),
    IconPicker: () => import("@/components/options/IconPicker"),
    TextURLDisplay: () => import("@/components/options/TextURLDisplay"),
    TextImageURLDisplay: () =>
      import("@/components/options/TextImageURLDisplay"),
    MultiTextDisplay: () => import("@/components/options/MultiTextDisplay"),
    MultiTextURLDisplay: () =>
      import("@/components/options/MultiTextURLDisplay"),
    MultiImageDisplay: () => import("@/components/options/MultiImageDisplay"),
    MultiImageURLDisplay: () =>
      import("@/components/options/MultiImageURLDisplay"),
    MultiTextImageURLDisplay: () =>
      import("@/components/options/MultiTextImageURLDisplay"),
    ProductPicker: () => import("@/components/options/ProductPicker"),
    Spinner: () => import("@/components/basis/spinner.vue"),
    Html: () => import("@/components/options/Html"),
    FontPicker: () => import("@/components/options/FontPicker")
  }
};
</script>

<style lang="scss" scoped>
.DAV-workshop__trunk {
  background-image: linear-gradient(to bottom, #54c7a2 50%, #f7f9fc 50%);
}
.DAV-panelArea {
  padding: 1px 16px 20px;
  background-color: #54c7a2;
}
.DAV-panelArea__wrap {
  position: relative;
  padding-left: 32px;
}
.DAV-panelArea__prev {
  position: absolute;
  top: 0;
  left: -4px;
  width: 32px;
  height: 48px;
  background-image: url($path + "bul_arr_left.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  text-indent: -9999px;
}
.DAV-panelArea__form--link {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 34px 0 16px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #40b690;
  //background-image: url($path + "ico_write.svg");
  background-repeat: no-repeat;
  background-position: right 18px center;
}
.DAV-panelArea__form--link span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
}
.DAV-panelArea__form--update {
  display: flex;
  justify-content: space-between;
}
.DAV-panelArea__form--save {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 72px;
  height: 48px;
  margin-left: 16px;
  background-color: #309574;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
}
.DAV-panelArea .formLayout {
  position: relative;
  width: 100%;
  height: 48px;
}
.DAV-panelArea .formLayout__input {
  width: 100%;
  height: 100%;
  padding: 0 40px 0 12px;
  box-sizing: border-box;
  border: 0;
  border-radius: 8px;
  font-weight: 500;
  outline: none;
}
.DAV-panelArea .formLayout__input--remove {
  position: absolute;
  top: 12px;
  right: 16px;
  width: 24px;
  height: 24px;
  background-image: url($path + "ico_form_close.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  text-indent: -9999px;
}
.DAV-tabArea {
  z-index: 1;
  position: absolute;
  top: 69px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f7f9fc;
  &--option-mode {
    //top: 0;
    top: 92px;
  }
}

.DAV-tabArea__nav {
  z-index: 2;
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 51px;
  padding: 0 16px;
  background-color: #f7f9fc;
}
.DAV-tabArea__link {
  margin-right: 40px;
  padding: 0 1px 8px;

  a {
    color: #606060;
  }
  &--selected {
    padding-bottom: 5px;
    border-bottom: 3px solid #309574;
  }
  &--selected a {
    color: #309574;
    font-weight: 500;
  }
}
.DAV-tabArea__frame {
  background-color: #ffffff;

  section {
    border-bottom: 8px solid #f7f9fc;
    padding: 0 16px 24px;
  }
}
.DAV-tabArea__design {
  background-color: #ffffff;
  &--editable {
    width: 100%;
    height: calc(
      100% - 205px
    ); //205px: header height + component name height + fixed bottom height
    overflow-y: auto;
    position: fixed;
    top: 149px;
  }
  &--uneditable {
    width: 100%;
    height: calc(100% - 149px); //205px: header height + component name height
    overflow-y: auto;
    position: fixed;
    top: 149px;
  }
  section {
    border-bottom: 8px solid #f7f9fc;
    padding: 0 16px 24px;
  }
}
.DAV-tabArea__block {
  margin-top: 16px;
}
.spaceBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;

  &__field {
    position: relative;

    span {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      color: #8d8d8d;
      font-size: 14px;
    }
    .frmBlock__textField {
      width: 75px;
      padding: 0 36px 0 0;
      text-align: right;
      font-weight: 500;
    }
  }
  &__center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 12px 0;

    &--inner {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 160px;
      height: 96px;
      margin: 0 12px;
      background-color: #f7f9fc;
      border: 1px dashed #dddfea;
      box-sizing: border-box;
      border-radius: 8px;
    }
  }
}
.frmBlock__boxWrap {
  position: relative;
  height: 264px;
  margin-top: 16px;
  border: 4px solid #f7f9fc;
  box-sizing: border-box;
  border-radius: 8px;
}
.currentDevice {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 48px;
  height: 48px;
  text-indent: -9999px;

  &__union {
    background-image: url($path + "bg_device_union_big.svg");
  }
  &__mobile {
    background-image: url($path + "bg_device_mobile_big.svg");
  }
  &__pc {
    background-image: url($path + "bg_device_pc_big.svg");
  }
}
.errorLayout {
  top: 51px;
  &__option-mode {
    top: 0;
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .DAV-tabArea {
    overflow-y: auto;
    top: 92px;
  }
  .DAV-panelArea {
    padding-top: 24px;
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .DAV-panelArea {
    &__option-mode {
      padding-top: 24px;
    }
  }
  .DAV-panelArea__wrap {
    padding-left: 0;
  }
}
@media screen and (max-width: 359px) {
  .frmBlock__layout--both {
    flex-direction: column;
  }
  .frmBlock__layout--both .frmBlock__label {
    margin-top: 8px;
  }
  .frmBlock__layout--both .frmBlock__label:first-child {
    margin-top: 0;
  }
}
</style>
